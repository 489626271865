Sidebar = {

    initialize: function(element, controller) {
		Sidebar._current = null;

        Sidebar._element = element;
        Sidebar._element.innerHTML = '';
        Sidebar._element.addEventListener('click', Sidebar.close);

        /* Create containers */

        var wrapper = new Element('div', { className: 'wrapper' });
		Sidebar._element.appendChild(wrapper);

		this.trapFocus(wrapper);

        var logo = new Element('div', { className: 'logo' });
		wrapper.appendChild(logo);

		var views = new Element('div', { className: 'views' });
		wrapper.appendChild(views);

        var salons = new Element('ul');
		wrapper.appendChild(salons);

        var archivedWrapper = new Element('li', { className: 'archived hidden' });
        archivedWrapper.hide();
		salons.appendChild(archivedWrapper);

		var archivedTitle = new Element('h3');
        archivedWrapper.appendChild(archivedTitle);
        archivedTitle.addEventListener('click', e => {
            archivedWrapper.classList.toggle('hidden');
        })

        var archivedList = new Element('ul');
		archivedWrapper.appendChild(archivedList);

		var buttons = new Element('div', { className: 'buttons' });
		wrapper.appendChild(buttons);


        /* Determine data */
        
        var actors = controller.getActors();
        var account = controller.account ? controller.account : controller.settings.account;
        var login = account.login ? account.login : account;
        var permissions = account.permissions ? account.permissions : account;


        /* Dashboard */

        var dashboard = actors.filter(i => i.stage == 'admin' && i.actor == 'dashboard').pop();

        if (dashboard) {
            var button = new Element('button', { className: 'button dashboard' }).update('<span class="icon"></span><span class="label">Filiaal monitor</span>');
			button.observe('click', () => {
				if (Sidebar._current) {
                    Sidebar._current.classList.remove('selected');
                    Sidebar._current = null;
				}

                controller.main.loader.change(dashboard);
                Sidebar.hide();
			});
			views.appendChild(button);
		}


        /* Settings */

		var settings = actors.filter(i => i.stage == 'admin' && i.actor == 'settings').pop();

        if (settings) {
            var button = new Element('button', { className: 'button settings' }).update('<span class="icon"></span><span class="label">' + settings.name + '</span>');
			button.observe('click', () => {
				if (Sidebar._current) {
                    Sidebar._current.classList.remove('selected');
                    Sidebar._current = null;
				}

                controller.main.loader.change(settings);
                Sidebar.hide();
			});
			views.appendChild(button);
		}

        
        /* Items */

        var items = actors.filter(i => i.stage == 'admin' && (i.actor != 'dashboard' && i.actor != 'settings'));

        items.forEach(i => {
            var item = new Element('li', { className: 'item' });
            item.tabIndex = 0;
            item.innerHTML = "<div class='name'><span class='icon'>" + i.icon + "</span><em>" + i.name + "</em></div>";
            item.observe('click', () => {
                if (Sidebar._current) {
                    Sidebar._current.classList.remove('selected');
                }

                Sidebar._current = item;
                item.classList.add('selected');

                controller.main.loader.change(i);
                Sidebar.hide();
            });
            
            item.observe('keypress', e => {
                if (e.key != 'Enter') return;

                if (Sidebar._current) {
                    Sidebar._current.classList.remove('selected');
                }

                Sidebar._current = item;
                item.classList.add('selected');

                controller.main.loader.change(i);
                Sidebar.hide();
            });
                                
            if (controller.main.loader.data.stage == i.stage && controller.main.loader.data.actor == i.actor) {
                item.classList.add('selected');
            }

            salons.insertBefore(item, salons.lastChild);
        });


        /* Active salons */

        var items = actors.filter(i => i.stage == 'salon' && i.id && i.status == 'active');

        items.forEach(i => {
            let url;
            let size = System.Features.Retina ? 80 : 40;
            
            if (i.photo) {
                url = `${i.photo}&w=${size}&h=${size}&fit=crop`;
            }

            let item = new Element('li', { className: 'salon' });
            item.tabIndex = 0;
            item.innerHTML = (url ? "<div class='photo'></div>" : "<div class='photo'></div>") + "<div class='name'><em>" + escapeHTML(i.fullname) + "</em><br>" + escapeHTML(i.formula) + "</div>";            
            item.observe('click', () => {
                if (Sidebar._current) {
                    Sidebar._current.classList.remove('selected');
                }

                Sidebar._current = item;
                item.classList.add('selected');

                controller.main.loader.change(i);
                Sidebar.hide();
            });

            item.observe('keypress', e => {
                if (e.key != 'Enter') return;

                if (Sidebar._current) {
                    Sidebar._current.classList.remove('selected');
                }

                Sidebar._current = item;
                item.classList.add('selected');

                controller.main.loader.change(i);
                Sidebar.hide();
            });
            
                                
            if (controller.main.loader.data.stage == i.stage && controller.main.loader.data.actor == i.actor) {
                item.classList.add('selected');
            }

            if (url) {
                setTimeout(() => {
                    item.querySelector('.photo').style.backgroundImage = 'url(' + url + ')';
                }, 1000)
            }

            salons.insertBefore(item, salons.lastChild);
        });



        /* Archived salons */

        var items = actors.filter(i => i.stage == 'salon' && i.id && i.status == 'archived');

        if (items.length && permissions.rights & 64) {
            items.forEach(i => {
                var item = new Element('li', { className: 'salon' });
                item.tabIndex = 0;
                item.innerHTML = escapeHTML(i.fullname);
                item.observe('click', () => {
                    if (Sidebar._current) {
                        Sidebar._current.classList.remove('selected');
                    }

                    Sidebar._current = item;
                    item.classList.add('selected');

                    controller.main.loader.change(i);
                    Sidebar.hide();
                });
                
                                
                if (controller.main.loader.data.stage == i.stage && controller.main.loader.data.actor == i.actor) {
                    item.classList.add('selected');
                    archivedWrapper.classList.remove('hidden');
                }

                archivedList.appendChild(item);
            });

            archivedTitle.innerHTML = 'Gearchiveerd (' + items.length + ')';
            archivedWrapper.show();
        }

        /* Logout */

		var button = new Element('button', { className: 'button logout' }).update('<span class="icon"></span><span class="label" title="' + login.username + '">Uitloggen</span>');
		button.observe('click', () => { Sidebar.hide(); controller.main.logout(); });
        buttons.appendChild(button);
       
        document.body.dataset.sidebar = 'hidden';
    },

    destroy: function() {
        delete document.body.dataset.sidebar;

        setTimeout(() => {
            Sidebar._element.innerHTML = '';
        }, 200);
    },

    trapFocus: function(element) {
        Sidebar._internal = {};
		Sidebar._internal.focusTrap = {};

		Sidebar._internal.focusTrap.frontCatch = new Element('div');
		Sidebar._internal.focusTrap.frontCatch.tabIndex = 0;
		Sidebar._internal.focusTrap.frontCatch.onfocus = () => Sidebar._internal.focusTrap.endTarget.focus();
		element.insertAdjacentElement('beforebegin', Sidebar._internal.focusTrap.frontCatch)

		Sidebar._internal.focusTrap.frontTarget = new Element('div');
		Sidebar._internal.focusTrap.frontTarget.tabIndex = -1;
		element.insertAdjacentElement('beforebegin', Sidebar._internal.focusTrap.frontTarget)

		Sidebar._internal.focusTrap.endCatch = new Element('div');
		Sidebar._internal.focusTrap.endCatch.tabIndex = 0;
		Sidebar._internal.focusTrap.endCatch.onfocus = () => Sidebar._internal.focusTrap.frontTarget.focus();
		element.insertAdjacentElement('afterend', Sidebar._internal.focusTrap.endCatch)

		Sidebar._internal.focusTrap.endTarget = new Element('div');
		Sidebar._internal.focusTrap.endTarget.tabIndex = -1;
		element.insertAdjacentElement('afterend', Sidebar._internal.focusTrap.endTarget)
    },

    show: function() {
        if (!Sidebar._element) {
            return;
        }

        if (document.body.classList.contains('overlay')) {
            return;
        }

        Sidebar._overlay = new Overlay({
            owner: Sidebar._element,
			onHide: Sidebar.hide,
			zIndex: 9999,
			animate: true
		});

        Sidebar._element.classList.add('visible');

        setTimeout(() => {
            if (Sidebar._internal.focusTrap.frontTarget) {
                Sidebar._internal.focusTrap.frontTarget.focus();
            }
        }, 100);

        document.body.dataset.sidebar = 'visible';
    },

    hide: function() {
        if (!Sidebar._element) {
            return;
        }

        Sidebar._overlay.destroy();
        Sidebar._element.classList.remove('visible');
        Sidebar._element.blur();

        document.body.dataset.sidebar = 'hidden';
    }
};