DeviceManager.Internal = {

    getCapabilities: async function() {
        let capabilities = {
            devices: {}
        };

        if (DeviceManager.Internal.PaymentTerminal) {
            capabilities.devices.paymentTerminal = await DeviceManager.Internal.PaymentTerminal.getCapabilities();
        }

        if (DeviceManager.Internal.ExternalDisplay) {
            capabilities.devices.externalDisplay = await DeviceManager.Internal.ExternalDisplay.getCapabilities();
        }

        if (DeviceManager.Internal.SheetPrinter) {
            capabilities.devices.sheetPrinter = await DeviceManager.Internal.SheetPrinter.getCapabilities();
        }

        return capabilities;
    }
};
