Banner = {
    initialize: function(element, controller) {
        let banner = null;

        if (Settings.channel == 'dev') {
            banner = {
                text:   'Deze versie van Salonhub is nog in ontwikkeling. Gebruik op eigen risico.',
                color:  'gray'
            }
        }

		if (controller.messages?.banner) {
            banner = controller.messages.banner;
        }

        if (banner) {
            document.body.classList.add('banner');

			element.dataset.text = banner.text;
			element.dataset.color = banner.color;
			element.dataset.link = banner.link || ''

            element.addEventListener('click', function() {	
                if (element.dataset.link) {
                    Runtime.Platform.openInBrowser(element.dataset.link);
                }
            });
        }
    }
};