CSS.insert(`

    .cloudconnect .windowContent iframe {
        clip-path: inset(30px 30px);
    }
`);


DeviceManager.Internal.PaymentTerminal.CcvCloudConnect = {

    getCapabilities: function() {
        return {
            id:         'ccv-cloud-connect',
            name:       'Salonhub Pay',
            features:   [ 'transaction', 'detectMethod', 'finalPayment', 'resumePayment', 'checkPayment', 'chooseTerminal' ]
        };
    },

    startTransaction: async function (options) {

        let skipInterface = false;
        let reference = null;


        /* Check if the terminal is available */

        let status = await new Promise(resolve => {
            new DeviceManager.Internal.PaymentTerminal.Wait({
                endpoint:   options.endpoint,
                client:     options.client,
                salon:      options.salon,
                device:     options.device,                        
                onResult:   status => resolve(status)
            });
        });

        if (status == 'stopped') {
            return { status: _PAYMENT_PAY_CANCELLED };
        }

        if (status == 'timeout') {
            return { status: _PAYMENT_PAY_REJECTED };
        }

        if (status == 'manualintervention') {
            return { status: _PAYMENT_PAY_INTERVENTION };
        }

        if (status == 'error') {
            return { status: _PAYMENT_PAY_CONNECTION_FAILED };
        }
        

        /* Start transaction on the server */

        let parameters = {
            location:   options.location,
            payment:    options.payment,
            amount:     options.amount,
            device:     options.device,
        };

        let createResponse = await fetch(`${options.endpoint}api/Payments.Terminals/${options.resume ? 'resume' : 'create'}/${options.client}:${options.salon}?provider=ccv-cloud-connect`, {
            method:     'POST',
            body:       JSON.stringify(parameters),
            headers:    {
                'Content-Type': 'application/json'
            }
        });

        let createResult;

        if (!createResponse.ok) {
            if (options.resume) {
                return { 
                    status: _PAYMENT_PAY_RESUME_FAILED 
                };
            }

            try {
                let createError = await createResponse.json();

                if (createError.reason == 'bad_credentials') { 
                    return { status: _PAYMENT_PAY_BAD_API_KEY };
                }

                if (createError.reason == 'invalid_config') { 
                    return { status: _PAYMENT_PAY_BAD_API_KEY };
                }

                if (createError.reason == 'bad_terminal') { 
                    return { status: _PAYMENT_PAY_TERMINAL_UNKNOWN };
                }

                if (createError.reason == 'connection_error') { 
                    return { status: _PAYMENT_PAY_NETWORK_FAILED };
                }

                if (createError.reason == 'rejected') {
                    return { status: _PAYMENT_PAY_REJECTED };
                }

                if (createError.reason == 'unknown') { 
                    return { status: _PAYMENT_PAY_NETWORK_UNKNOWN };
                }

                /* We encountered a server error on the API side, but that does not mean the payment failed. */

                if (createError.reason == 'server_error') { 
                    
                    /* First thing we do is wait for 15 seconds */

                    document.fire('shell:showPaymentStatus');

                    await new Promise((resolve) => setTimeout(resolve, 1000 * 15));
        
                    
                    /* Then we try to get the payment status, but not by reference, because we don't have that */

                    let getResponse = await fetch(`${options.endpoint}api/Payments.Terminals/getByPayment/${options.client}:${options.salon}?payment=${options.payment}`);
                    let getResult = await getResponse.json();

                    if (getResult.status == 'pending' || getResult.status == 'error') {
                        await new Promise((resolve) => setTimeout(resolve, 1000 * 15));      /* Wait another 15 seconds */
        
                        getResponse = await fetch(`${options.endpoint}api/Payments.Terminals/getByPayment/${options.client}:${options.salon}?payment=${options.payment}`);
                        getResult = await getResponse.json();

                        if (getResult.status == 'pending' || getResult.status == 'error') {
                            await new Promise((resolve) => setTimeout(resolve, 1000 * 15));      /* Wait another 15 seconds */
            
                            getResponse = await fetch(`${options.endpoint}api/Payments.Terminals/getByPayment/${options.client}:${options.salon}?payment=${options.payment}`);
                            getResult = await getResponse.json();
                        }
                    }

                    /* Wait for 200 ms */

                    document.fire('shell:hidePaymentStatus');
                    await new Promise((resolve) => setTimeout(resolve, 200));

                    /* Did we get an update from the API yet? */

                    if (getResult.status == 'error' || !getResult.reference) {
                        return { status: _PAYMENT_PAY_SERVER_ERROR };
                    }
                    else {
                        skipInterface = true;
                        reference = getResult.reference;
                    }
                }
            } catch (e) {
            }

            if (!skipInterface) {
                return {
                    status: _PAYMENT_PAY_CONNECTION_FAILED
                };
            }
        }

        if (!skipInterface) {
            createResult = await createResponse.json();
            reference = createResult.reference;

            if (createResult.log) {
                this.startIntercept();
            }

            /* Create window to show the payment interface */

            let interface = new Window({
                className:  'cloudconnect',
                modal:      true,
                width:      520,
                height:     480,
            });

            interface.show();

            let iframe = document.createElement('iframe');
            iframe.src = createResult.url;
            iframe.style.width = '100%';
            iframe.style.height = '100%';
            iframe.style.border = 'none';
            iframe.style.visibility = 'hidden';
            interface.contents.appendChild(iframe);

            await new Promise((resolve) => setTimeout(resolve, 500));

            iframe.style.visibility = 'visible';

            /* Wait for the payment to be completed */

            await new Promise((resolve) => {
                window.addEventListener('message', (event) => {
                    if (event.data.command && event.data.command == 'closePaymentInterface') {
                        resolve();
                    }
                });
            });

            /* Close the window */

            interface.close();

            if (createResult.log) {
                this.stopIntercept();
            }
        }

        
        /* Get the result of the payment */

        let getResponse = await fetch(`${options.endpoint}api/Payments.Terminals/getByReference/${options.client}:${options.salon}?reference=${reference}`);
        let getResult = await getResponse.json();


        /* Payment is still pending, wait up to 1 minutes and poll */

        if (getResult.status == 'pending') {
            document.fire('shell:showPaymentStatus');

            await new Promise((resolve) => setTimeout(resolve, 1000 * 30));      /* Wait 30 seconds */

            getResponse = await fetch(`${options.endpoint}api/Payments.Terminals/getByReference/${options.client}:${options.salon}?poll=true&reference=${reference}`);
            getResult = await getResponse.json();


            if (getResult.status == 'pending') {
                await new Promise((resolve) => setTimeout(resolve, 1000 * 15));      /* Wait another 15 seconds */

                getResponse = await fetch(`${options.endpoint}api/Payments.Terminals/getByReference/${options.client}:${options.salon}?poll=true&reference=${reference}`);
                getResult = await getResponse.json();


                if (getResult.status == 'pending') {
                    await new Promise((resolve) => setTimeout(resolve, 1000 * 15));      /* Wait another 15 seconds */
    
                    getResponse = await fetch(`${options.endpoint}api/Payments.Terminals/getByReference/${options.client}:${options.salon}?poll=true&reference=${reference}`);
                    getResult = await getResponse.json();
                }
            }

            document.fire('shell:hidePaymentStatus');
        }


        /* Payment was successful */

        if (getResult.status == 'success') {
            let method = _PAYMENT_DEBITCARD_;

            if (getResult.method != 'debit') {
                method = _PAYMENT_CREDITCARD_;
            }

            return {
                status: _PAYMENT_SUCCESS,
                data: {
                    status: _PAYMENT_STATUS_SUCCESS,
                    details: getResult.details,
                    method
                }
            }
        }

        /* Payment failed */

        if (getResult.status == 'failed') {
            return {
                status: _PAYMENT_SUCCESS,
                data: {
                    status: _PAYMENT_STATUS_FAILED,
                    reason: getResult.reason,
                    message: ''
                }
            }
        }
        
        /* Payment status is unknown */

        return {
            status: _PAYMENT_SUCCESS,
            data: {
                status: _PAYMENT_STATUS_UNKNOWN,
            }
        }
    },

    supports: function (feature) {
        if (feature == 'resumePayment') {
            return true;
        }

        if (feature == 'detectMethod') {
            return true;
        }

        if (feature == 'finalPayment') {
            return true;
        }

        if (feature == 'checkPayment') {
            return true;
        }

        if (feature == 'chooseTerminal') {
            return true;
        }
    },

    enabled: function(options) {
        return options.language == 'ccv-cloud-connect';
    },



    /* Intercept the Cloud Connect console output, for debugging purposes */

    startIntercept: function() {
        if (typeof ConsoleIntercept === 'undefined') {
            return;
        }

        DeviceManager.intercept = {
            reference: null,
            index: 0,
            actions: []
        };

        let abortShown = false;

        ConsoleIntercept.start((event) => {
            try {
                if (event.message.includes('Polling done, received')) {
                    let payload = event.message.split('Polling done, received')[1].trim();
                    let data = JSON.parse(payload);
                    
                    data.actions.forEach(action => {
                        if (action.type == 'SHOW_TEXT' && action.value.hasOwnProperty(String(DeviceManager.intercept.index))) {
                            DeviceManager.intercept.actions.push({
                                timestamp:  moment().format('YYYY-MM-DD HH:mm:ss'),
                                type:       'text',
                                value:      'custom:' + action.value[String(DeviceManager.intercept.index)],
                            });

                            DeviceManager.intercept.index++;
                        }

                        if (action.type == 'SHOW_ABORT_TEXT' && !abortShown) {
                            abortShown = true;

                            DeviceManager.intercept.actions.push({
                                timestamp:  moment().format('YYYY-MM-DD HH:mm:ss'),
                                type:       'text',
                                value:      'abort'
                            });
                        }

                        if (action.type == 'REDIRECT') {
                            DeviceManager.intercept.actions.push({
                                timestamp:  moment().format('YYYY-MM-DD HH:mm:ss'),
                                type:       'redirect',
                                value:      action.value
                            });
                        }
                    })
                    
                    DeviceManager.intercept.reference = data.reference;
                }

                if (event.message.includes('Abort click done')) {
                    DeviceManager.intercept.actions.push({
                        timestamp:  moment().format('YYYY-MM-DD HH:mm:ss'),
                        type:      'clicked',
                        value:     'abort'
                    });

                    DeviceManager.intercept.index++;
                }
            }
            catch (e) {
            }
        });
    },

    stopIntercept: function() {
        if (typeof ConsoleIntercept === 'undefined') {
            return;
        }
         
        ConsoleIntercept.stop();

        if (DeviceManager.intercept && DeviceManager.intercept.actions.length) {
            Telemetry.log({
                type:		'cloud-connect',
                message:	'Console intercepted',
                details: 	{
                    reference:  DeviceManager.intercept.reference,
                    actions:    DeviceManager.intercept.actions
                }
            });
        }
    }
};