CSS.insert(`
	.RuntimeAbout .headerBox { background: #eee; border-top-left-radius: 5px; border-top-right-radius: 5px; margin: -20px -20px 0px; height: 150px; display: flex; align-items: center; justify-content: center; }
	.RuntimeAbout .headerBox img { position: relative; top: 4px; }
	.RuntimeAbout .versions { background: #eee; border-radius: 6px; margin: 0; overflow: hidden; }
	.RuntimeAbout .versions div > span { font-size: 75%; }
	.RuntimeAbout .versions .status { font-size: 70%; color: #888; display: inline-block; height: 20px; line-height: 20px; vertical-align: top; }
	.RuntimeAbout .versions .status.ok::before { display: inline-block; content: '•'; color: #1ba011; font-size: 220%; height: 20px; line-height: 20px; vertical-align: top; margin-right: 3px; margin-top: -1px;}
	.RuntimeAbout .versions .status.warning::before { display: inline-block; content: '•'; color: #ad0c0c; font-size: 220%; height: 20px; line-height: 20px; vertical-align: top; margin-right: 3px; margin-top: -1px;}
	.RuntimeAbout .versions .main { position: relative; border-bottom: 1px solid #fff; padding: 0; margin: 0; height: 48px; }
	.RuntimeAbout .versions .main:last-child { border-bottom: none; }
	.RuntimeAbout .versions .main img { position: absolute; top: 8px; left: 8px; width: 32px; height: 32px; image-rendering: pixelated; }
	.RuntimeAbout .versions .main > div > span { position: absolute; top: 18px; left: 48px; }
	.RuntimeAbout .versions .main > div > span em { color: #888; font-style: normal; }
	.RuntimeAbout .versions .main .widget.button { position: absolute; right: 4px; top: 12px; }
	.RuntimeAbout .versions .main .status { position: absolute; left: 170px; top: 15px; }
	.RuntimeAbout .versions .update { position: relative; border-bottom: 1px solid #fff; margin: 0; background: var(--colors-red); color: #fff; }
	.RuntimeAbout .versions .update::before { position: absolute; content: ''; top: -8px; left: 46px; border-top: none; border-left: 8px solid rgba(0,0,0,0); border-bottom: 8px solid var(--colors-red); border-right: 8px solid rgba(0,0,0,0); }
	.RuntimeAbout .versions .update span { font-size: 0.8em; padding: 12px 100px 12px 12px; display: block; }
	.RuntimeAbout .versions .update .widget.button { position: absolute; right: 4px; top: 12px; }
	.RuntimeAbout .versions .update .widget.button button { border-color: #fff; }
	.RuntimeAbout .versions .optional { position: relative; border-bottom: 1px solid #fff; padding: 0; margin: 0; height: 40px; }
	.RuntimeAbout .versions .optional:last-child { border-bottom: none; }
	.RuntimeAbout .versions .optional img { position: absolute; top: 10px; left: 20px; width: 20px; height: 20px; }
	.RuntimeAbout .versions .optional > div > span { position: absolute; top: 14px; left: 48px; }
	.RuntimeAbout .versions .optional > div > span b { display: inline-block; width: 120px; }
	.RuntimeAbout .versions .optional > div > span.icon::before { position: absolute; top: -2px; left: -28px; width: 20px; height: 20px; display: block; font-family: SalonIcon; font-size: 18px; color: #666; }
	.RuntimeAbout .versions .optional > div > span.acrobat::before { content: ''; }
	.RuntimeAbout .versions .optional > div > span.documentation::before { content: ''; }
	.RuntimeAbout .versions .optional > div > span.rescue::before { content: ''; }
	.RuntimeAbout .versions .optional > div > span.phone::before { content: ''; }
	.RuntimeAbout .versions .optional > div > span.chat::before { content: '\\e900'; }
	.RuntimeAbout .versions .optional > div > span.whatsapp::before { content: '\\e901'; }
	.RuntimeAbout .versions .optional > div > span.email::before { content: '✉'; }
	.RuntimeAbout .versions .optional > div > span.client::before { content: '\\e945'; }
	.RuntimeAbout .versions .optional > div > span.user::before { content: 'u'; }
	.RuntimeAbout .versions .optional .widget.button { position: absolute; right: 4px; top: 8px; }
	.RuntimeAbout .versions .optional .status { position: absolute; right: 10px; top: 10px; }
	.RuntimeAbout .widget.header.smallestSize h3 { margin: 1.5em 0 0 !important; color: #aaa; }
`);

Runtime.About = Class.create();
Runtime.About.prototype = {
	initialize: function(controller, options) {
		this.controller = controller;

		this.options = Object.assign({
			expanded: false
		}, options || {})

		this.window = new Window({
			container: 	document.body,
			className:  'RuntimeAbout',
			width: 		800,
			height:		490,
			overlay:	true,
			onClose: 	this.close.bind(this),
		});

		var posVersion = null
		var hasAutoUpdate = false;

		if (this.controller.devices.external) {
			posVersion = this.controller.devices.external.getVersion();
			hasAutoUpdate = this.controller.devices.external.supports('autoUpdate');
		}

		var header = new Element('div');
		header.className = 'headerBox';
		this.window.appendChild(header);

		var logo = new Image();
		logo.src = '../../../assets/images/logo.svg';
		logo.width = 160;
		logo.height = 30;
		header.appendChild(logo);

		var layout = new Layout.Columns(this.window.contents, [
			{ name: 'left', width: '380px' },
			{ name: 'right', width: '380px' }
		]);


		new Widgets.Header(layout.columns.left, {
			title:	'Versie',
			size:	'smallest'
		});

		var versions = new Widgets.Panel(layout.columns.left, {
			className:	'versions',
			style:		'none'
		});


		/* Salonhub */

		let appName = 'Salonhub' + (Settings.channel != 'dev' ? '.app ' +  Settings.version : '.dev');

		if (System.Features.TrustedWebActivity) {
			if (System.OS.Chromebook) {
				appName = 'Salonhub ' + (Settings.channel != 'dev' ? Settings.version + ' ' : '') + '<em>voor Chromebook</em>';
			}
			else {
				appName = 'Salonhub ' + (Settings.channel != 'dev' ? Settings.version + ' ' : '') + '<em>voor Android</em>';
			}
		}

		this.appPanel = new Widgets.Panel(versions, {
			className:	'main',
			style:		'none'
		});

		this.appPanel.update(
			'<img src="../../android/icons/icon-xhdpi.png" width=32 height=32>' +
			'<span>' + appName + '</span>'
		);

		/* Salonhub POS */

		this.posPanel = new Widgets.Panel(versions, {
			className:	'main',
			style:		'none'
		});


		this.posPanel.update(
			'<img src="../../../assets/images/pos.png" width=32 height=32>' +
			'<span>SalonhubPOS ' + (posVersion ? posVersion : '') + '</span>' 
		);

		if (this.controller.devices.external) {
			if (this.controller.devices.external.enabled) {
				this.posPanel.container.appendChild(new Element('div', { className: 'status ok' }).update('actief'));
			} else {
				this.posPanel.container.appendChild(new Element('div', { className: 'status warning' }).update('geen verbinding'));
			}
		}

		new Widgets.Button(this.posPanel, {
			title: 'Instellingen',
			size: 'small',
			width: '80px',
			color: 'white',
			onClick: this.deviceSettings.bind(this)
		});


		var needsCatalinaUpdate = System.OS.Mac && !hasAutoUpdate && posVersion && versionCompare(posVersion, '2.5.1') < 0;

		if (needsCatalinaUpdate) {
			this.posUpdatePanel = new Widgets.Panel(versions, {
				className:	'update',
				style:		'none'
			});
		
			this.posUpdatePanel.update(
				'<span>Deze versie van SalonhubPOS is niet compatible met macOS Catalina of later. Download een nieuwe versie van onze website.</span>'
			);		

			new Widgets.Button(this.posUpdatePanel, {
				title: 'Download',
				size: 'small',
				color: 'red',
				width: '80px',
				onClick: this.installPos.bind(this)
			});
		}

		/* Account */

		if (typeof this.controller.account.login.username != 'undefined') {
			new Widgets.Header(layout.columns.left, {
				title:	'Account',
				size:	'smallest'
			});

			var account = new Widgets.Panel(layout.columns.left, {
				className:	'versions',
				style:		'none'
			});

			var panel = new Widgets.Panel(account, {
				className:	'optional',
				style:		'none'
			});

			panel.update(
				'<span class="icon client"><b>Klant:</b>	 ' + this.controller.account.client + '</span>'
			);

			var panel = new Widgets.Panel(account, {
				className:	'optional',
				style:		'none'
			});

			panel.update(
				'<span class="icon user"><b>Gebruikersnaam:</b> ' + this.controller.account.login.username + '</span>'
			);
		}

		new Widgets.Header(layout.columns.right, {
			title:	'Ondersteuning',
			size:	'smallest'
		});


		/* Documentatie */

		var versions = new Widgets.Panel(layout.columns.right, {
			className:	'versions',
			style:		'none'
		});

		var panel = new Widgets.Panel(versions, {
			className:	'optional',
			style:		'none'
		});

		panel.update(
			'<span class="icon documentation">Kennisbank</span>'
		);

		new Widgets.Button(panel, {
			title: 'Bekijk',
			size: 'small',
			width: '80px',
			color: 'white',
			onClick: this.openDocumentation.bind(this)
		});


		/* Remote */

		var panel = new Widgets.Panel(versions, {
			className: 'optional',
			style: 'none'
		});

		panel.update('<span class="icon rescue">Hulp op afstand</span>');

		new Widgets.Button(panel, {
			title: 'Start',
			size: 'small',
			width: '80px',
			color: 'white',
			onClick: this.openRemote.bind(this)
		});

		
		/* E-mail */

		var panel = new Widgets.Panel(versions, {
			className:	'optional',
			style:		'none'
		});

		panel.update(
			'<span class="icon email">Stuur een e-mail naar support@salonhub.nl</span>'
		);


		/* Whatsapp */

		var panel = new Widgets.Panel(versions, {
			className:	'optional',
			style:		'none'
		});

		panel.update(
			'<span class="icon whatsapp">Whatsapp met 06-21 29 1544</span>'
		);


		/* Phone */

		var panel = new Widgets.Panel(versions, {
			className:	'optional',
			style:		'none'
		});

		panel.update(
			'<span class="icon phone">Bel met (050) 850 3710</span>'
		);

		/* Close */

		new Widgets.Button(this.window.footer, {
			title:		'Sluiten',
			color: 		'green',
			onClick:	this.close.bind(this)
		});

		this.window.show();
	},

	update: function() {

	},

	installPos: function() {
		if (System.OS.Mac) {
			window.open('https://salonhub.nl/downloads/pos/SalonhubPos.dmg');
		} else {
			window.open('https://salonhub.nl/downloads/pos/setup.exe');
		}
	},

	openRemote: function() {
		window.open('https://salonhub.nl/remote');
	},

	openDocumentation: function() {
		window.open('https://www.salonhub.support');
	},

	deviceSettings: function() {
		this.close();

		let actors = this.controller.getActors();
		let settings = actors.filter(i => i.stage == 'admin' && i.actor == 'settings').pop();

		if (settings) {
			this.controller.main.loader.change({ ...settings, path: 'index/devices' });
		}
	},

	close: function(){
		this.window.close();
	}
};
