DeviceManager.Internal.ReceiptPrinter = {

    enabled: function(options) {
        return options.printer == '';
    },

    supports: function(feature) {
        if (feature == 'canAutoPrint') {
            return false;
        }
    },

    print: async function(receipt, callback) {
        let rendered = DeviceManager.Internal.ReceiptPrinter.render(receipt);

        /* Create iframe for printing */

        let frame = document.createElement('iframe');
        frame.src = 'about:blank';
        document.body.appendChild(frame);


        /* Write rendered receipt to iframe */

        frame.contentWindow.document.write(rendered);

        await new Promise(resolve => setTimeout(resolve, 500));


        /* Focus iframe */

        frame.focus();

        await new Promise(resolve => setTimeout(resolve, 500));


        /* Print iframe */
        
        frame.contentWindow.print();
    
        document.body.addEventListener('click', () => {
            if(frame) {
                frame.remove();
            }
        }, { once: true });

        setTimeout(() => {
            if(frame) {
                frame.remove();
            }
        }, 180 * 1000)

        if (callback) {
            callback();
        }
    },

    render: function(receipt) {
        let result = `
        
            <style>
    
                @page {
                    margin: 0;
                }
    
                @media print {
                    body {
                        padding: 12px 
                    }
                }
                
                * {
                    font-family: monospace;
                    font-size: 12px;
                }
    
                table {
                    min-width: 42ch;
                    cell-spacing: 0;
                }
    
                td:first-child {
                    min-width: 32ch;
                }
                td:last-child {
                    min-width: 10ch;
                }
                td:only-child {
                    min-width: 42ch;
                }
    
                h3 {
                    margin-bottom: 0;
                    font-size: 16px;
                }
            </style>
        `;
        
        result += `<table>`
    
        receipt.forEach(item => {
            if (item.type === 'title') {
                result += `<tr><td colspan=2><h3>${item.value}</h3></td></tr>`;
            }
    
            if (item.type === 'block') {
                if (item.value == "\n") {
                    item.value = "<br>";
                }
    
                if ('monospace' in item && item.monospace) {
                    result += `<tr><td colspan=2><pre>${item.value}</pre></td></tr>`;
                }
                else {
                    result += `<tr><td colspan=2>${item.value || '&nbsp;'}</td></tr>`;
                }
            }
    
            if (item.type === 'mutation') {
                result += `
                    <tr>
                        <td>${item.value || '&nbsp;'}</td>
                        <td>${item.amount}</td>
                    </tr>
                `;
            }
    
            if (item.type === 'total') {
                result += `
                    <tr>
                        <td>&nbsp;</td>
                        <td>==========</td>
                    </tr>
                    <tr>
                        <td><b>${item.value || 'Totaal'}</b></td>
                        <td><b>${item.amount}</b></td>
                    </tr>
                `;
            }
    
            if (item.type === 'line') {
                result += `
                    <tr>
                        <td colspan=2>------------------------------------------</td>
                    </tr>
                `;
            }
        });
    
        result += `</table>`;
    
        return result;
    }
}