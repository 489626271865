// Pusher.logToConsole = true;

DeviceManager.External.Pusher = Class.create({
	initialize: function(options) {
		this.options = Object.assign({
			guid: 						'',
			key: 						'',
			application:				'',
			configuration:				null,
			logger:						null,
	
			onInitialized: 				function() {},
			onEnabled: 					function() {},
			onDisabled: 				function() {},
			onFailed: 					function() {},
			onUpdateCapabilities: 		function() {}
		}, options || {})

		this.guid = this.options.guid;
		this.key = this.options.key;
		this.failed = false;
		this.stopped = false;
		this.initialized = false;
		this.callbacks = {};

		this.telemetry = new PusherTelemetry();
		
		this.connect();
	},

	connect: async function() {
		if (this.options.logger) {
			Pusher.log = message => this.options.logger.log('device:pusher', message.replace(/Pusher :  : /g, ''));
		}

		if (this.options.configuration.details) {
			this.options.onUpdateCapabilities(this.options.configuration.details);
		}

		let params = {
			guid: this.guid,
		}

		if (this.key) {
			params.key = this.key;
		}

		this.connection = new Pusher(this.options.configuration.key, {
			forceTLS: true,
			cluster: this.options.configuration.cluster,
			enabledTransports: this.options.configuration.transports,
			channelAuthorization: {
				endpoint: 	this.options.configuration.authorizer,
				params
			}
		});

		this.connection.connection.bind("connected", () => {
			this.state = 'connected';

			this.telemetry.track('connected');

			if (!this.initialized) {
				this.initialized = true;

				this.channel = this.connection.subscribe(this.options.configuration.channel);

				this.channel.bind('client-response', (message) => {
					this.response(message);

					this.options.onEnabled();
				});

				this.channel.bind('client-hello', (message) => {
					this.telemetry.track('hello');

					if (message.command == 'requestInfo') {
						this.options.onUpdateCapabilities(JSON.parse(message.data));
					}

					this.options.onEnabled();
				});

				this.channel.bind('client-goodbye', (message) => {
					this.telemetry.track('goodbye');

					this.options.onDisabled();
				});

				this.channel.bind('pusher:subscription_succeeded', () => {
					
					this.telemetry.track('subscribed');

					/* Connecting for the first time */
					if (!this.failed) {
						this.options.onInitialized(this);
						this.options.onEnabled();
					}

					/* Connecting after a failure */
					else {
						this.failed = false;

						setTimeout(() => {

							/* First attempt */
							this.command({ command: 'ping' }, {
								timeout: 4,
					
								onSuccess: response => {
									this.telemetry.track('ping', { result: true, attempt: 1 });
									this.options.onEnabled();
								},
					
								onFailure: () => {
									this.telemetry.track('ping', { result: false, attempt: 1 });

									/* Second attempt */
									setTimeout(() => {
										this.command({ command: 'ping' }, {
											timeout: 4,
								
											onSuccess: response => {
												this.telemetry.track('ping', { result: true, attempt: 2 });
												this.options.onEnabled();
											},

											onFailure: () => {
												this.telemetry.track('ping', { result: false, attempt: 2 });
											}			
										});
									}, 1000 * 10);
								}
							});
						}, 1000 * 4);
					}
				});
			}
		});

		this.connection.connection.bind("disconnected", (error) => {
			this.telemetry.track('disconnected', error);
		});

		this.connection.connection.bind("unavailable", (error) => {
			this.failed = true;
			this.options.onDisabled();

			this.telemetry.track('unavailable', error);
		});

		this.connection.connection.bind("error", (error) => {
			this.options.onFailed();

			this.telemetry.track('error', error);
		});
	},

	response: function(message) {
		if (message.command == 'requestInfo') {
			this.options.onUpdateCapabilities(JSON.parse(message.data));
		}

		if (typeof this.callbacks[message.id] != 'undefined') {
			if (this.callbacks[message.id].onSuccess) {
				this.callbacks[message.id].onSuccess(message.data);
				delete this.callbacks[message.id];
			}
		}
	},

	timeout: function(id) {
		if (typeof this.callbacks[id] != 'undefined') {
			if (this.callbacks[id].onFailure) {
				this.callbacks[id].onFailure();
				delete this.callbacks[id];
			}
		}
	},

	stop: function() {
		this.stopped = true;

		if (this.connection) {
			this.connection.disconnect();
		}
	},

	command: function(message, options) {
		options = options || {};
		
		let id = uniqueid();

		let callbacks = { onSuccess: null, onFailure: null }
		if (options.onSuccess) callbacks.onSuccess = options.onSuccess;
		if (options.onFailure) callbacks.onFailure = options.onFailure;

		if (options.onSuccess || options.onFailure) {
			this.callbacks[id] = callbacks;
		}

		if (options.onFailure && options.timeout) {
			window.setTimeout(this.timeout.bind(this, id), options.timeout * 1000);
		}

		let data = {
			id:			id,
			command:	message.command,
			options:	JSON.stringify(Object.assign({ application: this.options.application }, message.options || {})),
			data:		message.data || '',
		};

		this.channel.trigger("client-request", data);

		return true;
	}
});


class PusherTelemetry {

	constructor() {
		this.clear();

		setInterval(() => this.store(), 1000 * 60 * 60);
		window.addEventListener('beforeunload', () => this.store());
	}

	track(event, data) {
		if (event == 'error' || event == 'unavailable') {
			this.problems++;
		}

		data = Object.assign({ timestamp: moment().format('YYYY-MM-DD HH:mm:ss') }, data || {});
		
		this.events.push({ event, data });
	}

	clear() {
		this.start = moment();
		this.events = [];
		this.problems = 0;
	}

	store() {
		if (!navigator.onLine) {
			return;
		}

		if (this.problems > 0) {
			Telemetry.log({
				type:		'pusher-events',
				message:	'Pusher disconnected',
				details: 	{
					start:		this.start.format('YYYY-MM-DD HH:mm:ss'),
					end:		moment().format('YYYY-MM-DD HH:mm:ss'),
					problems:	this.problems,
					events:		this.events
				}
			});
		}

		this.clear();
	}
}
