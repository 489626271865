Runtime.Loader = Class.create({
	initialize: function(controller, wrapper) {
		this.controller = controller;
		this.wrapper = wrapper;

		this.data = null;
		this.mask = null;

		if (typeof Runtime.Loader.Platform == 'undefined') Runtime.Loader.Platform = new Runtime.Platform(this.controller);
	},

	destroy: function() {
		this.data = null;
		this.empty();

        window.localStorage.removeItem(this.controller.prefix() + 'view');
	},
    
    bootstrap: function(fallback) {
        var previous = JSON.parse(window.localStorage.getItem(this.controller.prefix() + 'view'));
        var current;

        if (this.controller.parameters.path) {
            current = {};
            
            if (this.controller.parameters.salon) {
                current.stage = this.controller.parameters.path.split('/')[0];
                current.actor = this.controller.parameters.salon;
            } else {
                current.stage = 'admin';
                current.actor = this.controller.parameters.path.split('/')[0];
            }

            current.view = this.controller.parameters.path.split('/')[1];
        }
        else {
            if (previous) {
                current = previous;
            }
            else {
                current = {
                    stage: fallback.stage,
                    actor: fallback.actor,
                }
            }
		}

		this.change(current);
    },


	change: function(data) {
		var items = this.controller.getActors();

		if (this.controller.account.permissions.type == 1) {
			items = items.filter(item => typeof item.status == 'undefined' || item.status != 'deleted');
		} else {
			items = items.filter(item => typeof item.status == 'undefined' || item.status == 'active');
		}

		var result = items.filter(function(item) { return item.actor == data.actor && item.stage == data.stage } ).pop();

		if (result) {
			this.data = result;
			this.data.view = data.view || null;
			this.data.path = data.path || null;
			this.load();
		}
		else {
			if (items.length) {
				this.data = items[0];
				this.load();
			}
			else {
				this.data = null;
				this.empty();
			}
		}
	},

	reload: function() {
		if (this.data) {
			this.load();
		} else {
			this.empty();
		}
	},

	load: function() {
        window.localStorage.setItem(this.controller.prefix() + 'view', JSON.stringify(this.data));
        
		if (this.browser) this.wrapper.removeChild(this.browser);
		this.browser = document.createElement('iframe');
		this.browser.id = 'browser';

		let path = '';

		if (this.data.view) {
			path = '?path=' + this.data.view;
		}

		if (this.data.path) {
			path = '?path=' + this.data.path;
		}

		switch (this.data.stage) {
			case 'salon':
				this.browser.src = '../../../external/views/salon/index.html' + path;
				window.document.title = "Salonhub - " + this.data.fullname;
				break;

			case 'admin':
				if (this.data.actor == 'dashboard') {
					this.browser.src = '../../../external/views/dashboard/index.html' + path;
					window.document.title = "Salonhub - Filiaal monitor";
				}

				if (this.data.actor == 'settings') {
					this.browser.src = '../../../external/views/settings/index.html' + path;
					window.document.title = "Salonhub - " + this.data.name;
				}

				if (this.data.actor == 'import') {
					this.browser.src = '../../../external/views/import/index.html' + path;
					window.document.title = "Salonhub - Importeren";
				}

				if (this.data.actor == 'main') {
					this.browser.src = '../../../external/views/admin/index.html' + path;
					window.document.title = "Salonhub - Administratie";
                }
                
                break;
		}

		this.wrapper.appendChild(this.browser);

		Application.api = this.api();
	},

	empty: function() {
		window.document.title = "Salonhub";

		if (this.browser) {
			this.wrapper.removeChild(this.browser);
			delete this.browser;
		}

		this.controller.main.buildView({
			lock: false,
			tabs: { items: [], current: null }
		});

		Sidebar.initialize(document.getElementById('sidebar'), this.controller);
	},

	api: function() {
		return this.controller.api +
				this.controller.account.client + '/' +
				this.controller.account.login.username + '/' +
				this.controller.account.login.password + '/' +
				this.data.stage + '/' +
				this.data.actor + '/';
	}
});
