Runtime.Main = Class.create({
	initialize: function(controller) {
		this.controller = controller;
		this.loader = new Runtime.Loader(this.controller, document.getElementById('wrapper'));
		this.connectionStatus = null;

        this.view = '';

        this.locked = false;
		this.actions = [];

		this.header = document.getElementById('header');
		this.content = document.getElementById('content');

		document.observe('shell:showPaymentStatus', this.showPaymentStatus.bind(this));
		document.observe('shell:hidePaymentStatus', this.hidePaymentStatus.bind(this));

		this.connect();
	},

	connect: function() {
		this.login(result => {
			switch(result) {
				case _CONNECTION_SUCCESS:					
					window.sessionStorage.setItem('connection', 'online');
					this.success();
					break;

				case _CONNECTION_FAILED:					
					this.logout();
					break;

				case _CONNECTION_OFFLINE:
				case _CONNECTION_UNAVAILABLE:
				case _CONNECTION_UNKNOWN:					
					ConnectionStatus.update(result);
					ConnectionStatus.showWindow();

					setTimeout(() => {
						this.retry();
					}, 1000 * (result == _CONNECTION_OFFLINE ? 15: 60));
					
					window.sessionStorage.setItem('connection', 'offline');
					this.success();
					break;

				case _CONNECTION_UPGRADE:		
					this.forceUpgrade(); 
					break;
			}
		});
	},

	retry: function() {
		this.login(result => {
			if (result == _CONNECTION_SUCCESS) {
				ConnectionStatus.update(result);

				window.sessionStorage.setItem('connection', 'online');
				this.success();
			}

			if (result == _CONNECTION_OFFLINE) {
				setTimeout(() => {
					this.retry();
				}, 1000 * 15)
			}

			if (result == _CONNECTION_UNAVAILABLE || result == _CONNECTION_UNKNOWN) {
				setTimeout(() => {
					this.retry();
				}, 1000 * 60)
			}
		});
	},

	login: function(callback) {
		this.controller.account.login = JSON.parse(window.localStorage.getItem(this.controller.prefix() + 'login'));
        this.controller.account.permissions = JSON.parse(window.localStorage.getItem(this.controller.prefix() + 'permissions'));

		let client = this.controller.getClient();

		if (!client || !this.controller.account.login) {
			callback();
			return;
        }


		let token = window.localStorage.getItem(this.controller.prefix() + 'token') || null;		
		let credentials;

		if (token) {
			credentials = `token=${token}`;
		} else {
			credentials = `client=${client}&user=${this.controller.account.login.username}&digest=${this.controller.account.login.password}`;
		}

        let url = `api/=${base32.stringify(credentials)}/Session.User/login`;

		new SafeApiLoader(url, {
			method: 	'post',
			parameters: {
				version:	Settings.version,
				guid:		this.controller.settings.guid,
			},

			onSuccess: 	function(transport) {
				if (transport.status == 0) {	// Failed to connect
                    this.controller.account.client = client;
					callback(_CONNECTION_OFFLINE);
					return;
				}

				if (transport.responseText == '') {
					console.log('connection failed');
					callback(_CONNECTION_FAILED); 
					return;
				}

				var response = JSON.parse(transport.responseText);
				if (response) {
					this.controller.api = response.api;
					this.controller.tokens = Object.assign({ 'Session.*': token }, response.tokens);
					this.controller.keys = response.keys;
                    this.controller.account.client = client;
					this.controller.account.permissions = response.permissions;
					this.controller.options = response.options || {};
					this.controller.messages = response.messages;

					if (response.tokens['Session.*'] && response.tokens['Session.*'] != token) {
						window.localStorage.setItem(this.controller.prefix() + 'token', response.tokens['Session.*']);
					}

					window.localStorage.setItem(this.controller.prefix() + 'permissions', JSON.stringify(this.controller.account.permissions));

					callback(_CONNECTION_SUCCESS);
				} else {
					/* To do: clear application show config screen */
					callback(_CONNECTION_FAILED);
				}
			}.bind(this),

			onFailure:	function(transport) {
				switch(transport.status) {
					case 401:		callback(_CONNECTION_FAILED); break;
					case 402:		callback(_CONNECTION_FAILED); break;
					case 404:		callback(_CONNECTION_UPGRADE); break;
					case 410:		callback(_CONNECTION_UPGRADE); break;
					case 503:		callback(_CONNECTION_UNAVAILABLE); break;
					default:		callback(_CONNECTION_UNKNOWN); break;
				}
			}.bind(this)
		});
	},

	logout: function() {
		Sidebar.destroy();
		this.header.update('');

        this.controller.account.login = {};
        this.controller.account.permissions = {};

        window.localStorage.removeItem('account');
        window.localStorage.removeItem(this.controller.prefix() + 'login');
        window.localStorage.removeItem(this.controller.prefix() + 'token');
        window.localStorage.removeItem(this.controller.prefix() + 'view');
        window.localStorage.removeItem(this.controller.prefix() + 'permissions');

		this.controller.login();
		this.loader.destroy();

		this.updateUrl();


		/* On mobile we need to reload, in order restore our login viewport */
		if (System.Type.Mobile) {
			location.reload();
		}
	},

	success: function() {
		Runtime.resetViewport();

		var actors = this.controller.getActors();

        if (actors.length) {
			this.loader.bootstrap(actors[0]);
		}

		Banner.initialize(document.getElementById('banner'), this.controller);
		Sidebar.initialize(document.getElementById('sidebar'), this.controller);
		Message.initialize(this.controller);
	},







	enableOverlay: function() {
		Overlay.count++;
		document.body.classList.add('overlay');
	},

	disableOverlay: function() {
		if (Window.count == 0) {
			document.body.classList.remove('overlay');
		}
		
		Overlay.count--;
	},

	buildView: function(data, response) {
		this.header.update('');

		var hamburger = new Element('button', { id: 'hamburger' });
		hamburger.observe('click', Sidebar.show);
		this.header.appendChild(hamburger);

		var icon = new Element('span', { className: 'icon' }).update('');
		hamburger.appendChild(icon);

		var current = new Element('span', { id: 'current' });
		hamburger.appendChild(current);

		if (this.loader.data) {
			var actors = this.controller.getActors();
			for (var i = 0; i < actors.length; i++) {
				if (actors[i].stage == this.loader.data.stage && actors[i].actor == this.loader.data.actor) {
					current.update(escapeHTML(actors[i].fullname || actors[i].name))
				}
			}
		}

		var status = new Element('div', { className: 'status' });
		status.observe('click', function() {
			ConnectionStatus.showWindow();
		}.bind(this));
		this.header.appendChild(status);

		var about = new Element('div', { id: 'about' }).update('?');
		this.header.appendChild(about);
		about.observe('click', () => { 
			if (document.body.classList.contains('overlay')) {
				return;
			}

			new Runtime.About(this.controller) 
		});
		


		if (data.tabs.items.length) {
			var navigation = new Element('div', { id: 'navigation' });
			this.header.appendChild(navigation);

			this.tabs = [];

			for (var i = 0; i < data.tabs.items.length; i++) {
				let item = data.tabs.items[i];

				let element = new Element('button', { 'class': 'item' });
				element.innerHTML = (item.icon ? "<span class='icon'>" + item.icon + "</span> " : "") + "<span class='label'>" + item.name + "</span>";
				element.addEventListener('click', e => {
					if (!document.body.classList.contains('overlay')) {
						navigation.querySelectorAll('.selected').forEach(e => e.classList.remove('selected'));
						element.classList.add('selected');
						response(item.id);
					}
				});
	
				if (!item.enabled) {
					element.disabled = true;
				}
	
				if (item.id == data.tabs.current) {
					element.classList.add('selected');
				}
	
				this.tabs[item.id] = element;
								
				navigation.appendChild(element);
			}

			var actions = new Element('div', { className: 'actions' });
			navigation.appendChild(actions);

			if (data.lock) {
				var lock = new Element('button', { 'id': 'lock', 'class': 'expert' });
				actions.appendChild(lock);
				lock.addEventListener('click', function(code) {
					if (!document.body.classList.contains('overlay')) {
						this.toggleLock(code);
					}
				}.bind(this, data.lock.code))

				this.updateLock();
			}

			if (this.controller.settings.devices?.externalDisplay?.method) {
				var display = new Element('button', { id: 'display' }).update('\uE966');
				display.observe('click', () => this.controller.devices.isExternalDisplayOpen() ? this.controller.devices.closeExternalDisplay() : this.controller.devices.openExternalDisplay());
				actions.appendChild(display);
			}

			Print.initialize(actions, this.controller, this.actions);
			Export.initialize(actions, this.controller, this.actions);

			if (data.tabs.current) {
				this.changeView(data.tabs.current);
			}
		}
    },

	activateView: function(id) {
		var element = this.tabs[id];
		var navigation = document.getElementById('navigation');

		if (element) {
			[...navigation.children].forEach(i => i.classList.remove('selected'));
			element.classList.add('selected');
		}

		this.changeView(id);
    },
		
    changeView: function(id) {
        this.view = id;
		this.updateUrl();
	},
	
	updateUrl: function() {
		
		/* Disable updating URLs on webOS */
		
		if (navigator.userAgent.includes('NetCast')) {
			return;
		}

        let url = window.location.href.replace(window.location.search, '');
		let source = location.search.match(/source=([^&]*)/i);

        url += '?client=' + this.controller.account.client;

		if (this.loader.data) {
			if (this.loader.data.stage == 'salon') {
				url += '&salon=' + this.loader.data.actor;
				url += '&path=' + this.loader.data.stage + '/' + this.view;
			} else {
				url += '&path=' + this.loader.data.actor + '/' + this.view;
			}
		}

		if (source) {
			url += '&' + source[0];
		}

		if (window.history.replaceState) {
			window.history.replaceState(null, '', url)
		}	
    },
	
	updateActions: function() {
		document.fire('application:restore');

		Print.update(this.actions);
		Export.update(this.actions);
	},

	updateLock: function() {
		var lock = document.getElementById('lock');

		if (this.locked) {
			lock.update("\uE91D");
			lock.classList.remove('unlocked');
		} else {
			lock.update("\uE91E");
			lock.classList.add('unlocked');
		}
	},

	checkLock: function(code) {
		var lock = document.getElementById('lock');

		this.window = new ChallengeWindow(lock, {
			onCheck: function(value) {
				return value == code;
			}.bind(this),

			onSave:	function(data) {
				this.locked = false;
				Runtime.Loader.Platform.command('unlock', data);
				this.updateLock();
			}.bind(this)
		});
	},

	toggleLock: function(code) {
		if (this.locked) {
			this.checkLock(code);
		} else {
			this.locked = true;
			Runtime.Loader.Platform.command('lock');
			this.updateLock();
		}
	},

	showPaymentStatus: function() {
		if (this.paymentStatus == null) {
			this.paymentStatus = new Window.Status('Wachten op betaling...');
		}
	},

	hidePaymentStatus: function() {
		if (this.paymentStatus != null) {
			this.paymentStatus.close();
			this.paymentStatus = null;
		}
	},

	forceUpgrade: function() {
		new Window.Alert({
			message:		"Upgrade nu!",
			explaination:	"De versie van Salonhub die u gebruikt moet bijgewerkt worden voordat u verder kunt werken. Klik op OK om de nieuwste versie van Salonhub te installeren."
		})
	}
});
