Runtime.Storage = {
	read: async function(options) {		
        const data = JSON.parse(window.localStorage.getItem(options.name));

        if (data) {
            return data;
        }

        if (options.defaults) {
            return options.defaults;
        }
	},

	write: function(name, value) {
        window.localStorage.setItem(name, JSON.stringify(value));
    },
    
    remove: function(name) {
        window.localStorage.removeItem(name);
    }
};