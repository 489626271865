Message = {
    initialize: async function(controller) {
        let messages = [];

        if (controller.messages?.items) {
            messages = messages.concat(controller.messages.items);
        }

        if (Settings.firstrun) {
            let build = Settings.version.split('.').pop();
            
            if (build) {
                messages.unshift({ id: 'first-run-' + build });
            }
        }

        Message._messages = messages;
        Message._read = await Runtime.Storage.read({
            name:       'read-messages',
            defaults:   [],
        });

        this.show();
    },

    show: async function(item) {
        if (Message._messages.length) {
            let item = Message._messages.shift();

            if (!item.id || !this.hasBeenRead(item.id)) {
                if (!item.payload && item.id) {
                    try {
                        let response = await fetch('https://master.salonhub.nl/latest/api/Application.System.Messages/get?id=' + item.id);
                        item = await response.json();
                    } catch(e) {
                    }
                }
        
                if (item.payload) {
                    await MessageWindow.show(item);

                    if (item.id && item.once) {
                        this.markAsRead(item.id);
                    }

                    await new Promise(resolve => setTimeout(resolve, 2000));
                }
            }

            if (Message._messages.length) {
                this.show();
            }
        }
    },

    hasBeenRead: function(id) {     
        return Message._read.includes(id);
    },

    markAsRead: function(id) {
        Message._read.unshift(id)
        Message._read = Message._read.slice(0, 10);
        Runtime.Storage.write('read-messages', Message._read);
    }
};


MessageWindow = {
    show: async function(item) {
        let payload = item.payload;

        await new Promise(resolve => {
            let window = new Window({
                modal:		true,
                width: 		payload.width || 500,
                height: 	payload.height || 'auto',
                className: 	'MessageWindow',
                onClose:    resolve,
            });

            if (payload.image) {
                let found = payload.image.match(/([a-z]+)#(.+)/iu);
                if (found) {
                    payload.image = `../../../assets/icons/${found[1]}/icons/${found[2]}.svg`;
                }
            }

            let template = (payload) => html`
                <article>
                    ${ payload.image ? html`<img src=${ payload.image } alt=''>` : '' }

                    <div>
                        <h3>                        
                            ${payload.title}
                        </h3>
                        <p>
                            ${html([payload.contents])}
                        </p>
                    </div>
                </article>
            `;

            new Widgets.Panel(window, {
                style:      'none',
                template:   template(payload),
            });

            new Widgets.Button(window.footer, {
                title:       'Sluiten',
                color:      payload.color || 'blue',
                onClick:    () => window.close(),
            });

            window.show();
        });
    }
}