
CSS.insert(`
	.externalDisplayPreview .desktop {
		display: flex;
		flex-direction: column;
        align-items: center; 
        background: var(--panel-background);
        border-radius: 5px;
		padding: 40px 20px 10px 20px;
	}

	.externalDisplayPreview .desktop .display {
		display: flex;
		position: relative;
		border: 8px solid black;
		border-radius: 8px;
	}

	.externalDisplayPreview .desktop .display::after {
		display: block;
		content: "";
		position: absolute;

		margin-left: -25%;
		width: 50%;
		height: 8px;
		left: 50%;
		bottom: -16px;

		background: #555;
		border-radius: 0 0 8px 8px;
	}

	.externalDisplayPreview .desktop .display iframe { 
		border: none;
	}

	.externalDisplayPreview .desktop .widget.dropdown { 
		margin-top: 30px;
	}
`);

DeviceManager.Internal.ExternalDisplayPreview = Class.create();
DeviceManager.Internal.ExternalDisplayPreview.prototype = {
	initialize: function(options) {
        this.options = Object.assign({
            url:    '',
        }, options || {});

		this.window = new Window({
			container: 	document.body,
			title:		'Voorvertoning extern scherm',
			className:  'externalDisplayPreview',
			width: 		640,
			height:		540
		});

		let desktop = document.createElement('div');
		desktop.classList.add('desktop');
		this.window.contents.appendChild(desktop);

		let display = document.createElement('div');
		display.classList.add('display');
		desktop.appendChild(display);

        let iframe = document.createElement('iframe');
        iframe.src = this.options.url;
        iframe.style.width = '512px';
        iframe.style.height = '288px';
        display.appendChild(iframe);


		let aspectRatio = new Widgets.DropdownMenu(desktop, {
			value:      '16:9',
			items:      [
				{ title: '16:9', value: '16:9' },
				{ title: '4:3', value: '4:3' }
			],

			onChange: (value) => {
				if (value == '16:9') {
					iframe.style.width = '512px';
				}

				if (value == '4:3') {
					iframe.style.width = '384px';
				}
			}
		});

        new Widgets.Button(this.window.footer, {
			title:		'Sluiten',
			color:		'green',
			onClick:	() => this.window.close()
		});

		this.window.show();
	}
};
