DeviceManager.External.Http = Class.create({
	initialize: async function(options) {
		this.options = Object.assign({
			guid: 						'',
			host:						'127.0.0.1',
			application:				'',
			logger:						null,

			keepAlive:					true,

			onInitialized: 				function() {},
			onFailed: 					function() {},
			onEnabled: 					function() {},
			onDisabled: 				function() {},
			onUpgrade: 					function() {},
			onUpdateCapabilities: 		function() {},
		}, options || {})

		this.host = this.options.host;

		if (this.options.guid && this.options.configuration) {
			if (this.options.configuration.details) {
				this.options.onUpdateCapabilities(this.options.configuration.details);
			}

			if (this.options.configuration.local) {
				this.host = this.options.configuration.local;
			}
		}

		this.connected = false;

		this.timer = null;
		this.retry = null;

		this.connect();
	},

	getEndpoint: function(ip) {
		return 'http://' + ip + ':4321';
	},

	connect: function() {
		this.retry = null;

		this.command({ command: 'requestInfo' }, {
			onSuccess: function(data) {
				data = JSON.parse(data);

				this.connected = true;

				this.options.onUpdateCapabilities(data);
				this.options.onInitialized(this);

				this.options.onEnabled();
			}.bind(this),

			onFailure: function() {
				this.options.onDisabled();

				if (this.options.keepAlive) {
					this.retry = window.setTimeout(this.connect.bind(this), 1000 * 30)
				}
				else {
					this.options.onFailed();
				}
			}.bind(this),
		});
	},

	ping: function() {
		this.command({ command: 'requestInfo' }, {
			onSuccess: function(data) {
				try {
					data = JSON.parse(data);
					this.options.onUpdateCapabilities(data);
					this.options.onEnabled();
				}
				catch(e) {
					this.options.onDisabled();
				}
			}.bind(this),

			onFailure: function() {
				this.options.onDisabled();
			}.bind(this),
		});
	},

	reset: function() {
		if (this.timer) {
			window.clearTimeout(this.timer);
		}

		if (this.connected && this.options.keepAlive) {
			this.timer = window.setTimeout(this.ping.bind(this), 1000 * 30);
		}
	},

	stop: function() {
		this.connected = false;

		if (this.timer) {
			window.clearTimeout(this.timer);
		}

		if (this.retry) {
			window.clearTimeout(this.retry);
		}

		this.options.onDisabled();
	},

	command: function(message, options) {
		options = Object.assign({
			timeout:	4
		}, options || {});

		this.reset();

		var url = this.getEndpoint(this.host) + '/' + message.command + '?';
		url += Object.toQueryString(Object.assign({ application: this.options.application }, message.options || {}));

		request = {
			method: 	'post',
			timeout:	options.timeout,
			postBody:	message.data || '',

			onSuccess: function(transport){
				if (transport.status == 0) {
					if (options.onFailure) {
						options.onFailure();
					}

					return;
				}

				var response = transport.responseText;

				if (this.options.logger) {
					this.options.logger.log('device:http', {
						type:   'response',
						url:	url,
						data:	response,
					});
				}

				if (options.onSuccess) {
					options.onSuccess(response);
				}
			}.bind(this),

			onFailure: function() {
				if (options.onFailure) {
					options.onFailure();
				}
			}.bind(this),

			onTimeout: function() {
				if (options.onFailure) {
					options.onFailure();
				}
			}.bind(this)
		};

		if (this.options.logger) {
			this.options.logger.log('device:http', {
				type:   'request',
				url:	url,
				data:	message.data || '',
			});
		}

		new Ajax.Request(url, request);

		return true;
	}
});