class DeviceLogger {
	constructor(options) {
		this.options = options;
		this.queue = [];
		this.sequence = 0;
		this.session = uniqueid();

		setInterval(() => this.store(), 1000);
	}

	log(type, payload) {
		this.sequence++;

		let data = new FormData();
		data.append('type', type);
		data.append('source', 'client');
		data.append('application', this.options.application);
		data.append('payload', typeof payload === 'string' ? payload : JSON.stringify(payload));
		data.append('session', this.session);
		data.append('sequence', this.sequence);
		data.append('timestamp', moment().format('YYYY-MM-DD HH:mm:ss'));

		this.queue.push(data);
	}

	store() {
		if (!navigator.onLine) {
			return;
		}

		if (!this.queue.length) {
			return;
		}

		let data = this.queue.shift()

		if (data) {
			navigator.sendBeacon('https://master.salonhub.nl/latest/api/PointOfSale.Client/log', data);
		}
	}
}