DeviceManager.Internal.PaymentTerminal = {

    getCapabilities: async function() {
        let capabilities = {
            items:      [],
            devices:    []
        };

        if (DeviceManager.Internal.PaymentTerminal.CcvCloudConnect) {
            capabilities.items.push(DeviceManager.Internal.PaymentTerminal.CcvCloudConnect.getCapabilities());
        }

        if (DeviceManager.Internal.PaymentTerminal.Adyen) {
            capabilities.items.push(DeviceManager.Internal.PaymentTerminal.Adyen.getCapabilities());
        }

        return capabilities;
    }
};