
ConnectionStatus = {
	_window: null,
	_state: null,
	_method: null,
	_first: true,

	showWindow: function() {
		if (ConnectionStatus._window == null || ConnectionStatus._window.closed) {
			ConnectionStatus._window = new ConnectionStatus.Window({ state: ConnectionStatus._state });
			document.body.dataset.connection = 'red';
		}
	},

	hideWindow: function() {
		if (ConnectionStatus._window != null) {
			
			if (!ConnectionStatus._window.closed) {
				ConnectionStatus._window.close();
			}

			ConnectionStatus._window = null;
		}
	},

	update: function(state, method) {		
		ConnectionStatus._state = state;

		if (ConnectionStatus._state == _CONNECTION_SUCCESS) {

			if (ConnectionStatus._method != method) {
				document.body.dataset.connection = method == 'polling' ? 'orange' : 'green';
			}

			if (!ConnectionStatus._first) {
				ConnectionStatus.hideWindow();
			}
		}
		else {
			document.body.dataset.connection = 'red';
		}

		ConnectionStatus._method = method || null;
		ConnectionStatus._first = false;
	}
}







CSS.insert(`
	.SalonhubConnectionWindow .disconnected  { text-align: center; position: relative; }
	.SalonhubConnectionWindow .disconnected div > span { position: absolute; top: -10px; left: 0; width: 100%; font-family: SalonIcon; font-size: 100pt; color: #ccc; }
	.Capacitor.Application .SalonhubConnectionWindow .disconnected div > span { font-size: 115pt }
	.SalonhubConnectionWindow .disconnected div > span > span { position: absolute; top: 90px; left: 30px; width: 100%; font-size: 40pt; color: #900; }
	.SalonhubConnectionWindow .status  { text-align: center; color: #000; font-size: 12px; font-weight: var(--font-weight-bold); line-height: 150%;  }
	.SalonhubConnectionWindow .status p + p { margin-top: 1.3em }
	.SalonhubConnectionWindow .message  { text-align: center; color: #888; font-size: 11px; line-height: 150%; }
`);

ConnectionStatus.Window = Class.create();
ConnectionStatus.Window.prototype = {
	initialize: function(options) {
		this.options = options;
		this.closed = false;

		let left = 14;
		let top = 54;

		if (System.OS.Mac && System.Runtime.Electron) {
			left = 92;
		}

		if (System.OS.iOS && System.Runtime.Capacitor) {
			left = 10;
			top = 72;
		}

		this.window = new Window({
			container: 	document.body,
			className:  'SalonhubConnectionWindow',
			width: 		260,
			height:		'auto',
			modal:		false,
			overlay:	true,
			pointer:	{ side: 'top', position: 10 },
			left:		left,
			top: 		top,
			onClose:	this.close.bind(this)
		});

		new Widgets.Panel(this.window, {
			className:	'disconnected',
			style:		'none',
			height:		'140px'
		}).update('<span>☁<span>×</span><span>')

		this.status = new Widgets.Panel(this.window, {
			className:	'status',
			style:		'none'
		});

		var horizontal = new Layout.Flex(this.window, {
			pack: 'center',
			items: [
				{ name: 'center' }
			]
		});

		new Widgets.Button(horizontal.items.center, {
			title:		'Bekijk agenda',
			icon:		'calendar',
			size:		'small',
			onClick:	function() {
							this.close();
						}.bind(this)
		});

		this.separator = new Widgets.Separator(this.window, {
			width: 			'page'
		});

		this.message = new Widgets.Panel(this.window, {
			style: 		'none',
			className:	'message'
		});

		switch (this.options.state) {
			case _CONNECTION_OFFLINE:
			case _CONNECTION_UNAVAILABLE:
				this.status.update("<p>Salonhub is tijdelijk niet beschikbaar.</p><p>U kunt momenteel alleen al bekende afspraken in uw agenda bekijken.</p>");
				break;

			default:
				this.status.update("<p>Er is een fout opgetreden tijdens het inloggen, probeer het later a.u.b. nog eens.</p>");
		}

		this.ping();
		this.interval = setInterval(this.ping.bind(this), 30 * 1000);

		this.window.show();
	},

	ping: function() {
		new Ajax.Request('https://salonhub.online/status.html', {
			method: 'get',
			onSuccess: function(transport) {
				if (transport.status == 0) {
					this.failure();
					return;
				}

				var data = transport.responseText;
				this.message.update(data);
			}.bind(this),

			onFailure: this.failure.bind(this)
		});
	},

	failure: function() {
		this.message.update('Het lijkt er op dat er momenteel geen internet beschikbaar is, neem a.u.b. contact op met uw internet provider');
	},

	close: function() {
		clearInterval(this.interval);

		this.closed = true;
		this.window.close();
	}
};
