(function() {

    /* Handle state of external display */

    let State = new Proxy(
        { 
            enabled: false, 
            method:	null 
        }, {
            get(target, prop) { return target[prop] },
            set(target, prop, value) {
                if (prop == 'enabled') {
                    document.body.dataset.externalDisplay = value ? 'true' : 'false';
                }
    
                target[prop] = value;
                return true;
            }
        }
    );


    let ExternalDisplay = {

        initialize: async function(args) {
    
            /* Initialize casting method */
    
            if (args.method == 'cast') {
                let id = sessionStorage.getItem('external_display_connection_id');
    
                if (id) {
                    ExternalDisplay.open(args, id);
                }
            }
    
            /* Initialize window method */
    
            if (args.method == 'id' || args.method == 'auto') {
                let open = await ExternalDisplay.isWindowOpen();
    
                if (open) {
                    State.method = 'window';
                    State.enabled = true;
                }
            }
        },
    
        close: async function() {
            if (!State.enabled) {
                return;
            }
    
            if (State.method == 'cast') {
                State.connection.terminate();
            }
    
            if (State.method == 'window') {
                const channel = new BroadcastChannel('external_display');
                channel.postMessage({ type: 'close' });
    
                State.method = null;
                State.enabled = false;
            }
        },
    
        open: async function(args, id) {
            if (args.method == '') {
                return;
            }

            /* Presentation API */
    
            if (args.method == 'cast') {
                if (!('PresentationRequest' in window)) {
                    return;
                }
    
                let connection;
                let channel;

                let url = '../../generic/js/devicemanager/internal/external-display/render.html?method=cast&fullscreen=auto&configuration=' + encodeURIComponent(JSON.stringify(args));
                let request = new PresentationRequest(url)
                let availability = await request.getAvailability();		

                /* If availability is false, then wait or timeout */

                if (!availability.value) {
                    let changed = new Promise(resolve => availability.onchange = resolve);
                    let timeout = new Promise(resolve => setTimeout(resolve, 2000));
                    await Promise.any([changed, timeout]);
                }

                /* If timeout out, then return */

                if (!availability.value) {
                    return;
                }

                /* If availability is true, then connect */

                if (id) {
                    try {
                        connection = await request.reconnect(id);
                    }
                    catch(e) {
                        State.enabled = false;
                        State.method = null;
                        State.connection = null;
            
                        sessionStorage.removeItem('external_display_connection_id');
                    }
                }
                else {
                    connection = await request.start();
                }
    
                connection.onconnect = () => {
                    channel = new BroadcastChannel('external_display');
                    channel.onmessage = (event) => {
                        try {
                            connection.send(JSON.stringify(event.data));
                        }
                        catch {
                        }
                    };
    
                    State.enabled = true;
                    State.method = 'cast';
                    State.connection = connection;
    
                    sessionStorage.setItem('external_display_connection_id', connection.id);
                }
    
                connection.onterminate = () => {
                    if (channel) {
                        channel.close();
                    }
    
                    State.enabled = false;
                    State.method = null;
                    State.connection = null;
    
                    sessionStorage.removeItem('external_display_connection_id');
                }
    
                return;
            }
    
    
            /* Screen placement API */
    
            if (!window.getScreenDetails) {
                return;
            }
    
            const details = await window.getScreenDetails();
            let display;
    
            if (args.method == 'id') {
                display = details.screens.find((display) => display.label === args.display);
            }
    
            if (args.method == 'auto') {
                display = details.screens.find((display) => display.isPrimary === false);
            }
    
            if (display) {
                let open = await ExternalDisplay.isWindowOpen();
    
                if (!open) {
                    let url = '../../generic/js/devicemanager/internal/external-display/render.html?method=window&fullscreen=manual&display=' + encodeURIComponent(display.label) + '&configuration=' + encodeURIComponent(JSON.stringify(args));
                    window.open(url, '_blank', 'width=300,height=300,top=40,left=40');
    
                    State.method = 'window';
                    State.enabled = true;
    
                    let channel = new BroadcastChannel('external_display');
                    channel.onmessage = (event) => {
                        if (event.data.type == 'closed') { 
                            State.method = null;
                            State.enabled = false;
                        }
                    };
                }
            }
        },

        isOpen: function() {
            return State.enabled;
        },

        getCapabilities: async function() {
            let capabilities = {
                cast:	'PresentationRequest' in window,
                items:	[]
            };
            
            if (window.getScreenDetails) {
                try {
                    const details = await window.getScreenDetails();
        
                    details.screens.forEach((display, index) => capabilities.items.push({
                        id:				display.label,
                        title:			`${display.label} (${display.width} × ${display.height})`,
                        number:			index + 1,
                        bounds:			{ x: display.left, y: display.top, width: display.width, height: display.height },
                        scaleFactor:	display.devicePixelRatio,
                        internal:		display.isInternal,
                        primary:		display.isPrimary
                    }));
                }
                catch {
                }
            }

            if (capabilities.items.length == 0) {
                capabilities.items.push({
                    id:				'',
                    title:			`Scherm 1 (${screen.width} × ${screen.height})`,
                    number:			1,
                    bounds:			{ x: 0, y: 0, width: screen.width, height: screen.height },
                    scaleFactor:	window.devicePixelRatio,
                    internal:		true,
                    primary:		true
                })	
            }

            return capabilities;
        },




        /* Internal  functions */
    
        isWindowOpen: function() {
            let handled = false;
    
            return new Promise((resolve) => {
                const channel = new BroadcastChannel('external_display');
    
                channel.addEventListener('message', (event) => {
                    if (event.data.type && event.data.type == 'pong') {
    
                        if (channel) {
                            channel.close();
                        }
            
                        handled = true;
                        resolve(true);
                    }
                });
    
                channel.postMessage({ type: 'ping' });
    
                setTimeout(() => {	
                    if (channel) {
                        channel.close();
                    }
    
                    if (!handled) {
                        resolve(false);
                    }
                }, 1000);
            });
        }
    }

    DeviceManager.Internal.ExternalDisplay = ExternalDisplay;
})();

